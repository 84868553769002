@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto";
}

img {
  width:8%;
  height:auto;
}

.main {
  padding: 47px 185px;
  color: #e9cf56;
  background: linear-gradient(
    188.24deg,
    rgba(169, 214, 255, 0.31) -85.85%,
    #13191f 15.14%,
    #000000 77.69%,
    #474669 127.75%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main__title {
  font-size: 40px;
}

.main__logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 245px;
}

.main__text {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 30px;
  line-height: 47px;
  text-align: center;
  margin-top: 25px;
  color: linear-gradient(90deg, #e9cf56 0%, #ffeb83 119.64%);

  /* identical to box height */
}

.main__btn {
  background-color: transparent;
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin: auto;
  text-decoration: none;
  color: #e9cf56;
  border: 1px solid #e9cf56;
  border-radius: 50px;
  padding: 10px 20px;
}

.about {
  padding: 47px 185px;
  background: linear-gradient(90deg, #e9cf56 0%, #ffeb83 119.64%);
  color: #000000;
}
.about__header {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 100px;
  line-height: 234px;

  color: #000000;
}

.about__mission {
  margin-top: 25px;
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 45px;
  line-height: 53px;
  text-align: center;
  color: #000000;
}

.about__text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #000000;
  margin-top: 15px;
}

.about__footer {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 100px;
  line-height: 234px;
  text-align: right;
  color: #000000;
}
.form {
  padding: 47px 185px;
  background: #000000;
  color: #e9cf56;
}
.form__msg {
  margin-top: 100px;
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  /* identical to box height */

  text-align: center;

  color: #edd036;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form__text {
  background-color: #000000;
}

.form__btn {
  margin-left: 200px;
  background-color: transparent;
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  text-decoration: none;
  color: #e9cf56;
  border: 1px solid #e9cf56;
  border-radius: 50px;
  padding: 10px 20px;
}

.form__btn:hover {
  cursor: pointer;
}

.contact {
  padding: 47px 185px;
  background: linear-gradient(90deg, #e9cf56 0%, #ffeb83 119.64%);
  color: #000000;
  text-align: center;
}

.contact__txt {
  padding-bottom: 70px;
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 71px;
  line-height: 234px;
}

.contact__links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__links__link {
  margin-right: 20px;
  color: #000000;
  text-decoration: none;
  font-size: 20px;
}

.contact__links__link:first-child {
  text-decoration: underline;
}

.final {
  padding: 47px 185px;
  background: #000000;
  color: #e9cf56;
  text-align: center;
}
.final__header {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 100px;
  line-height: 234px;
}

.final__txt {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
}

input,
select,
textarea {
  color: #e9cf56;
  background-color: #000000;
  margin-left: 300px;
  margin-top: 40px;
  border-bottom: solid 2px #e9cf56;
  border-top: none;
  border-left: none;
  border-right: none;
}

input:focus {
  outline: none;
}

::placeholder {
  padding-bottom: 20px;
  color: #e9cf56;
  font-size: 15px;
}
